import './App.css';
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/Homepage";
import Project from "./components/Project";
import Book from "./components/Book";

const App = () => {
  
  return (
    
    <div className="App">

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/theproject" element={<Project />} />
        <Route path="/thebook" element={<Book />} />
      </Routes>
    </div>
  );
}

export default App;
