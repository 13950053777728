import React, { useEffect, useRef } from 'react';
// import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
// import pdfFile from '../../assets/PDFBIGHEAD.pdf';
import './book.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import couverture from '../../assets/bigheaded.jpg';
import remerciements from '../../assets/remerciements.jpg';
// import bigheaded6 from '../../assets/bigheaded6.jpg';

const images = [
    // require('../../assets/bigheaded.jpg'),
    // require('../../assets/bigheaded5.jpg'),
    require('../../assets/bigheaded6.jpg'),
    require('../../assets/bigheaded7.jpg'),
    require('../../assets/bigheaded8.jpg'),
    require('../../assets/bigheaded9.jpg'),
    require('../../assets/bigheaded10.jpg'),
    require('../../assets/bigheaded11.jpg'),
    require('../../assets/bigheaded12.jpg'),
    require('../../assets/bigheaded13.jpg'),
    require('../../assets/bigheaded14.jpg'),
    require('../../assets/bigheaded15.jpg'),
    require('../../assets/bigheaded16.jpg'),
    require('../../assets/bigheaded17.jpg'),
    require('../../assets/bigheaded18.jpg'),
    require('../../assets/bigheaded19.jpg'),
    require('../../assets/bigheaded20.jpg'),
    require('../../assets/bigheaded21.jpg'),
    require('../../assets/bigheaded22.jpg'),
    require('../../assets/bigheaded23.jpg'),
    require('../../assets/bigheaded24.jpg'),
    require('../../assets/bigheaded25.jpg'),
    require('../../assets/bigheaded26.jpg'),
    require('../../assets/bigheaded27.jpg'),
    require('../../assets/bigheaded28.jpg'),
    require('../../assets/bigheaded29.jpg'),
    require('../../assets/bigheaded30.jpg'),
    require('../../assets/bigheaded31.jpg'),
    require('../../assets/bigheaded32.jpg'),
    require('../../assets/bigheaded33.jpg'),
    require('../../assets/bigheaded34.jpg'),
    require('../../assets/bigheaded35.jpg'),
    require('../../assets/bigheaded36.jpg'),
    require('../../assets/bigheaded37.jpg'),
    require('../../assets/bigheaded38.jpg'),
    require('../../assets/bigheaded39.jpg'),
    require('../../assets/bigheaded40.jpg'),
    require('../../assets/bigheaded41.jpg'),
    require('../../assets/42.jpg'),
    require('../../assets/bigheaded43.jpg'),
    require('../../assets/bigheaded44.jpg'),
    require('../../assets/bigheaded45.jpg'),
    require('../../assets/bigheaded46.jpg'),
    require('../../assets/bigheaded47.jpg'),
    require('../../assets/bigheaded48.jpg'),
    require('../../assets/bigheaded49.jpg'),
    require('../../assets/bigheaded50.jpg'),
    require('../../assets/bigheaded51.jpg'),
    require('../../assets/bigheaded52.jpg'),
    require('../../assets/bigheaded53.jpg'),
    require('../../assets/bigheaded54.jpg'),
    require('../../assets/bigheaded55.jpg'),
    require('../../assets/bigheaded56.jpg'),
    require('../../assets/bigheaded57.jpg'),
    require('../../assets/bigheaded58.jpg'),
    require('../../assets/bigheaded59.jpg'),
    require('../../assets/bigheaded60.jpg'),
    require('../../assets/bigheaded61.jpg'),
    require('../../assets/bigheaded62.jpg'),
    require('../../assets/bigheaded63.jpg'),
    require('../../assets/bigheaded64.jpg'),
    require('../../assets/bigheaded65.jpg'),
    require('../../assets/bigheaded66.jpg'),
    require('../../assets/bigheaded67.jpg'),
    require('../../assets/bigheaded68.jpg'),
    require('../../assets/bigheaded69.jpg'),
    require('../../assets/70.jpg'),
    require('../../assets/71.jpg'),
    require('../../assets/72.jpg'),
    require('../../assets/73.jpg'),
    require('../../assets/74.jpg'),
    require('../../assets/75.jpg'),
    require('../../assets/76.jpg'),
    require('../../assets/77.jpg'),
    require('../../assets/bigheaded70.jpg'),
    require('../../assets/bigheaded71.jpg'),
    require('../../assets/bigheaded74.jpg'),
];

const imagesPDF = [
    // require('../../assets/bigheaded.jpg'),
    // require('../../assets/bigheaded5.jpg'),
    require('../../assets/bigheaded6.jpg'),
    require('../../assets/bigheaded7.jpg'),
    require('../../assets/bigheaded8.jpg'),
    require('../../assets/bigheaded9.jpg'),
    require('../../assets/bigheaded10.jpg'),
    require('../../assets/bigheaded11.jpg'),
    require('../../assets/bigheaded13.jpg'),
    require('../../assets/bigheaded12.jpg'),
    require('../../assets/bigheaded14.jpg'),
    require('../../assets/bigheaded15.jpg'),
    require('../../assets/bigheaded17.jpg'),
    require('../../assets/bigheaded16.jpg'),
    require('../../assets/bigheaded18.jpg'),
    require('../../assets/bigheaded19.jpg'),
    require('../../assets/bigheaded21.jpg'),
    require('../../assets/bigheaded20.jpg'),
    require('../../assets/bigheaded22.jpg'),
    require('../../assets/bigheaded23.jpg'),
    require('../../assets/bigheaded25.jpg'),
    require('../../assets/bigheaded24.jpg'),
    
    require('../../assets/bigheaded26.jpg'),
    require('../../assets/bigheaded27.jpg'),
    require('../../assets/bigheaded29.jpg'),
    require('../../assets/bigheaded28.jpg'),
    
    require('../../assets/bigheaded30.jpg'),
    require('../../assets/bigheaded31.jpg'),
    require('../../assets/bigheaded33.jpg'),
    require('../../assets/bigheaded32.jpg'),
    
    require('../../assets/bigheaded34.jpg'),
    require('../../assets/bigheaded35.jpg'),
    require('../../assets/bigheaded37.jpg'),
    require('../../assets/bigheaded36.jpg'),
    
    require('../../assets/bigheaded38.jpg'),
    require('../../assets/bigheaded39.jpg'),
    require('../../assets/bigheaded41.jpg'),
    require('../../assets/bigheaded40.jpg'),
    
    require('../../assets/42.jpg'),
    require('../../assets/bigheaded43.jpg'),
    require('../../assets/bigheaded45.jpg'),
    require('../../assets/bigheaded44.jpg'),
    
    require('../../assets/bigheaded46.jpg'),
    require('../../assets/bigheaded47.jpg'),
    require('../../assets/bigheaded49.jpg'),
    require('../../assets/bigheaded48.jpg'),
    
    require('../../assets/bigheaded50.jpg'),
    require('../../assets/bigheaded51.jpg'),
    require('../../assets/bigheaded53.jpg'),
    require('../../assets/bigheaded52.jpg'),
    
    require('../../assets/bigheaded54.jpg'),
    require('../../assets/bigheaded55.jpg'),
    require('../../assets/bigheaded57.jpg'),
    require('../../assets/bigheaded56.jpg'),
    
    require('../../assets/bigheaded58.jpg'),
    require('../../assets/bigheaded59.jpg'),
    require('../../assets/bigheaded61.jpg'),
    require('../../assets/bigheaded60.jpg'),
    
    require('../../assets/bigheaded62.jpg'),
    require('../../assets/bigheaded63.jpg'),
    require('../../assets/bigheaded65.jpg'),
    require('../../assets/bigheaded64.jpg'),
    
    require('../../assets/bigheaded66.jpg'),
    require('../../assets/bigheaded67.jpg'),
    require('../../assets/bigheaded69.jpg'),
    require('../../assets/bigheaded68.jpg'),
    
    require('../../assets/70.jpg'),
    require('../../assets/71.jpg'),
    require('../../assets/73.jpg'),
    require('../../assets/72.jpg'),
    
    require('../../assets/74.jpg'),
    require('../../assets/75.jpg'),
    require('../../assets/76.jpg'),
    require('../../assets/77.jpg'),
    require('../../assets/bigheaded70.jpg'),
    require('../../assets/bigheaded71.jpg'),
    require('../../assets/bigheaded74.jpg'),
];


const Book = () => {

    const goToHomePage = () => {
        window.location.href = '/';
    }

    const pagesRef = useRef(null);

    useEffect(() => {
        const pages = pagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            if (i % 2 === 0) {
                page.style.zIndex = (pages.length - i);
            }
        }

        for (let i = 0; i < pages.length; i++) {
            pages[i].pageNum = i + 1;
            pages[i].onclick = function () {
                if (this.pageNum % 2 === 0) {
                    this.classList.remove('flipped');
                    this.previousElementSibling.classList.remove('flipped');
                } else {
                    this.classList.add('flipped');
                    this.nextElementSibling.classList.add('flipped');
                }
            }
        }
    }, []);


    return (
        <div className="book">
            <div className="back-arrow" onClick={goToHomePage}>
                <FontAwesomeIcon className="custom-size" icon={faArrowLeft} />
            </div>

            <div id="pages" className="pages" ref={pagesRef}>
                <div className="page" style={{ backgroundImage: `url(${couverture})` }}></div>
                <div className="page"></div>
                <div className="page" style={{ backgroundImage: `url(${remerciements})` }}></div>

                {images.map((imageSrc, index) => (
                    <div key={index} className="page" style={{ backgroundImage: `url(${imageSrc})` }}></div>
                ))}
            </div>


            <div className="image-gallery">
                <div className="pagepdf" style={{ backgroundImage: `url(${couverture})` }}></div>
                <div className="pagepdf" style={{ backgroundImage: `url(${remerciements})` }}></div>
                {imagesPDF.map((imageSrc, index) => (
                    <img
                        key={index}
                        src={imageSrc}
                        alt="gallery"
                        className="gallery-image" // class CSS pour styliser chaque image
                    />
                ))}
            </div>
        </div>


    );
};

export default Book;
