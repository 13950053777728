import React, { useRef, useEffect } from "react";
import "./homepage.css";
import ballImage1 from '../../assets/pioupiou.png';
import ballImage2 from '../../assets/punk.png';
import ballImage3 from '../../assets/rockeur.png';
import ballImage4 from '../../assets/roux.png';
import alienhead from '../../assets/alienhead.png';
import girlshead from '../../assets/girlshead.png';
import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';

const Homepage = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasWidth = window.innerWidth;
  const canvasHeight = window.innerHeight;

  const images = [ballImage1, ballImage2, ballImage3, ballImage4, alienhead, girlshead];

  const getBallSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 600) {
      return 400 * (screenWidth / 600); // 400 est la taille maximale et 600 est la largeur d'écran maximale pour cette taille
    }
    return 400; // Retourne la taille par défaut si la largeur est supérieure à 600px
  };

  const ballSize = getBallSize();
  const margin = ballSize / 2; // Ajuster la marge à la moitié de la taille de la balle
  const ballCount = 6;
  const balls = Array.from({ length: ballCount }).map((_, index) => {
    const img = new Image();
    img.src = images[index];

    // Définir les positions initiales pour les quatre coins
    const positions = [
      { x: margin, y: margin }, // Top-left corner
      { x: canvasWidth - margin - ballSize, y: margin }, // Top-right corner
      { x: margin, y: canvasHeight - margin - ballSize }, // Bottom-left corner
      { x: canvasWidth - margin - ballSize, y: canvasHeight - margin - ballSize }, // Bottom-right corner
    ];

    // Inverser les directions initiales pour qu'elles se dirigent vers le centre
    const initialDeltas = [
      { dx: 1, dy: 1 },
      { dx: -1, dy: 1 },
      { dx: 1, dy: -1 },
      { dx: -1, dy: -1 },
    ];

    return {
      img: img,
      x: positions[index % positions.length].x,
      y: positions[index % positions.length].y,
      dx: initialDeltas[index % initialDeltas.length].dx * (Math.random() * 4),
      dy: initialDeltas[index % initialDeltas.length].dy * (Math.random() * 4),
      width: ballSize,
      height: ballSize,
    };

  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const draw = () => {
      ctx.clearRect(0, 0, canvasWidth, canvasHeight);

      balls.forEach((ball) => {
        ctx.drawImage(ball.img, ball.x, ball.y, ball.width, ball.height);

        // Ajuster la logique de rebondissement en fonction de la taille de la balle
        if (ball.x + ball.dx > canvasWidth - ball.width || ball.x + ball.dx < 0) {
          ball.dx = -ball.dx;
        }
        if (ball.y + ball.dy > canvasHeight - ball.height || ball.y + ball.dy < 0) {
          ball.dy = -ball.dy;
        }

        ball.x += ball.dx;
        ball.y += ball.dy;
      });
    };

    const interval = setInterval(draw, 35);

    return () => {
      clearInterval(interval);
    };
  }, [canvasWidth, canvasHeight, balls]);

  return (
    <div ref={containerRef} className="container">
      <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} />
      <div className="logo">
        <img src={logo} alt="Logo" className="myImagehomepage" />
        <div className="links">
          <Link to="/theproject" className="link">The project</Link>
          <Link to="/thebook" className="link">The book</Link>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
