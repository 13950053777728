import logo from '../../assets/logo.png';
import martien from '../../assets/martien.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import "./project.css";
import { Link } from 'react-router-dom';


const Project = () => {

  const goToHomePage = () => {
    window.location.href = '/';
  }

  return (
    <div className='container_project overflow'>
      <div className="back-arrow" onClick={goToHomePage}>
        <FontAwesomeIcon className="custom-size" icon={faArrowLeft} />
      </div>

      <div className="project">
        <img src={logo} alt="projectName" className="myImage" />
      </div>

      <div className='theproject'>
        The project
      </div>

      <div className='container_presentation'>
        <div className='image'>
          <img src={martien} alt="ProjectMArtien" className="martien" />
        </div>

        <div className='presentation'>
  Big Headed - A Book of Recommendations is an almost professional project crafted by Jeanne Taillard and Timothy Alexandre to assist us in our job search. After spending 5 years at DDB Paris and a year at The LEGO Group, we're now exploring new opportunities.
  Throughout our careers, we've had the chance of collaborating with incredible people. In this book, you'll find what some of them have to say about us.
  Feel free to explore the printed version of this book
  on our Instagram account <Link to="https://www.instagram.com/jeannettim/" target="_blank" rel="noreferrer" className="link_reseau"> @jeannettim </Link>
  and check out more of our work at <Link to="http://jeanne-timothy.com/" target="_blank" rel="noreferrer" className="link_reseau"> jeanne-timothy.com </Link>
</div>
      </div>

    </div>
  );
}

export default Project;